import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => (
  <header className="header">
    <div className="logo">
      <Link to="/">communitytravelaus.com</Link>
    </div>
    <nav>
      <Link to="/about" className="nav-link">About</Link>
      <Link to="/top-casino-hotels" className="nav-link">Top Casino Hotels</Link>
      <Link to="/casino-hotels" className="nav-link">Casino Hotels</Link>
      <Link to="/casino-hotels-2" className="nav-link">Casino Hotels 2</Link> {/* Yeni bağlantı */}
      <Link to="/contact-us" className="nav-link">Contact Us</Link>
    </nav>
  </header>
);

export default Header;
