import React from 'react';
import './ContactUsSection.css';

const ContactUsSection = () => (
  <section className="contact-us" id="contact-us">
    <h2>Contact Us</h2>
    <p>
      Thank you for visiting <strong>Community Travelaus</strong>, your trusted source for exploring the finest
      casino hotels across Australia. Whether you have questions about our hotel listings, need assistance with planning
      your next casino experience, or want to partner with us, we’re here to help. We are committed to providing you with
      accurate and helpful information to make your casino hotel journey enjoyable and unforgettable.
    </p>
    <p>
      Please feel free to reach out to us via our dedicated email addresses for different inquiries. For general questions or
      support, contact us at <a href="mailto:info@communitytravelaus.com">info@communitytravelaus.com</a>.
      For business or partnership inquiries, reach us at <a href="mailto:business@communitytravelaus.com">business@communitytravelaus.com</a>.
      We look forward to connecting with you!
    </p>

    <form className="contact-form">
      <label>
        Name:
        <input type="text" placeholder="Your Full Name" required />
      </label>
      <label>
        Email:
        <input type="email" placeholder="Your Email Address" required />
      </label>
      <label>
        Subject:
        <select required>
          <option value="">Select a Subject</option>
          <option value="general">General Inquiry</option>
          <option value="booking">Booking Assistance</option>
          <option value="partnership">Partnership Inquiry</option>
          <option value="feedback">Feedback/Suggestions</option>
        </select>
      </label>
      <label>
        Message:
        <textarea placeholder="Your Message" required></textarea>
      </label>
      <button type="submit">Send Message</button>
    </form>
  </section>
);

export default ContactUsSection;
